/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var fh = $('#the-form').outerHeight() + 65;
        $('#form-bg').css({'height':fh+'px'});
        $(window).load(function() {
          setTimeout(function() {
            $('#m').animate({'opacity':'1'}, 1000, 'easeInOutCirc');
            $('#m').addClass('skr8');
            $('#h').animate({'bottom':'0'}, 1300, 'easeOutExpo');
            $('#i').animate({'top':'0'}, 800, 'easeOutCirc');
            setTimeout(function() {
              $('#the-brand').animate({'opacity':'1'}, 800, 'easeInCirc');
              setTimeout(function() {
                $("#webuild").typed({
                  strings: ["WE BUILD WEBSITES"],
                  typeSpeed: 63
                });
                setTimeout(function() {
                  $('#form-bg').animate({'top':'-50px', 'left':'0'}, 1700, 'easeOutSine');
                  $('#cursor').animate({'top':'-50px', 'left':'0'}, 1700, 'easeOutSine');
                  $('.wpcf7-form').animate({'opacity':'1'}, 600, 'easeOutSine');
                  setTimeout(function() {
                    $('#cursor').addClass('droppings');
                    $('#cursor').animate({'top':'-70px', 'opacity':'0', 'left':'-13px'}, 600, 'easeOutSine');
                    $('#form-bg').animate({'top':'0px'}, 1200, 'easeOutElastic');
                  }, 1700);
                  setTimeout(function() {
                    $('#the-form').removeClass('hid');
                  }, 2000);
                }, 1500);
              }, 1000);
            }, 300);
          }, 500);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
